var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main",style:({
    display: 'flex',
    width: '100%',
    backgroundImage: `url(${_vm.image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
  })},[_c('div',{staticClass:"box"})])
}
var staticRenderFns = []

export { render, staticRenderFns }